button.partner-continue-button{
  padding: 1.75rem 1.5rem;
  margin-top: 0rem;
  border-radius: 0.5rem !important;
  line-height: 19.2px;
  transition: 0.2s box-shadow;

  &:hover {
    box-shadow: 0px 0px 3px 0px #6725f4;
  }
}